import React, { useEffect, useRef, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ListPage from 'src/views/wishlist/list'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import type { Props } from 'src/hooks/useSearchParams'

function Page(props: Props) {
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('List', person)

  const timerRef = useRef<NodeJS.Timeout | undefined>()
  const wishlistId = props?.params?.wishlistId

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  return (
    <>
      <GatsbySeo
        title="Wishlist"
        description="Wishlist Page"
        language="pt-BR"
        noindex
        nofollow
      />
      <ListPage wishlistId={wishlistId} />
    </>
  )
}

export const query = graphql`
  query WishlistListPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
