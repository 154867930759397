import React from 'react'
import IconButton from 'src/components/ui/VivaraIconButton'
import ToolPencilIcon from 'src/images/svg/icon-toolpencil'
import TrashIcon from 'src/images/svg/icon-trash'

import './wishlist-actions.scss'

interface WishlistActionsProps {
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsEditWishlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDefaultWishlist: boolean
}

function WishlistActions({
  setIsDeleteModalOpen,
  setIsEditWishlistModalOpen,
  isDefaultWishlist,
}: WishlistActionsProps) {
  const baseClass = 'wishlistActions'

  return (
    <div className={`${baseClass}__actions`}>
      {!isDefaultWishlist && (
        <>
          <IconButton
            icon={<ToolPencilIcon />}
            aria-label="Editar"
            onClick={() => setIsEditWishlistModalOpen(true)}
            className={`${baseClass}__actions-icon`}
          >
            <span>Editar Nome</span>
          </IconButton>
          <IconButton
            icon={<TrashIcon />}
            aria-label="Excluir"
            onClick={() => setIsDeleteModalOpen(true)}
            className={`${baseClass}__actions-icon`}
          >
            <span>Excluir lista</span>
          </IconButton>
        </>
      )}
    </div>
  )
}

export default WishlistActions
