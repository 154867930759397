import React, { useEffect, useState } from 'react'
import { RadioGroup, RadioOption } from '@faststore/ui'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import BaseModal from 'src/components/modal/BaseModal'
import { sortSkuVariations } from 'src/utils/product/sortSkuVariations'
import useProductCategory from 'src/sdk/product/useProductCategory'
import { Image } from 'src/components/ui/Image'
import type { SkuFragmentFragment } from '@generated/graphql'
import getSkuFromValueProperty from 'src/utils/wishlist/getSkuFromValueProperty'

import storeConfig from '../../../../store.config'

import './styles.scss'

interface WishlistMeasuresModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentSize: string
  productIndex: number
  productHasVariant: SkuFragmentFragment[]
  categoryTree: Array<{
    item: string
    name: string
    position: number
  }>
}

function WishlistMeasuresModal({
  isOpen,
  setIsOpen,
  currentSize,
  productIndex,
  productHasVariant,
  categoryTree,
}: WishlistMeasuresModalProps) {
  const { changeWishlistProductSize } = useWishlistContext()
  const [chosenOption, setChosenOption] = useState<string>('0')
  const { account } = storeConfig

  useEffect(() => {
    setChosenOption(currentSize)
  }, [])

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const option = e?.currentTarget?.value

    setChosenOption(option)

    if (option !== currentSize && option !== '0') {
      const productSku = getSkuFromValueProperty(productHasVariant, option)

      if (productSku) {
        changeWishlistProductSize(productIndex, option, productSku)
      }
    }

    setIsOpen(false)
  }

  const onModalClose = () => {
    if (chosenOption !== currentSize && chosenOption !== '0') {
      const productSku = getSkuFromValueProperty(
        productHasVariant,
        chosenOption
      )

      if (productSku) {
        changeWishlistProductSize(productIndex, chosenOption, productSku)
      }
    }

    setIsOpen(false)
  }

  const { imgTypeOptions, imgUrl, measureTitle } = useProductCategory({
    categoryTree,
  })

  const changeClassName =
    imgUrl === '' ? 'wishlist-measures-modal-no-gif' : 'wishlist-measures-modal'

  const imgOptions = {
    ...imgTypeOptions,
    aspectRatio: 1,
    layout: 'fixed' as const,
    backgroundColor: '#f0f0f0',
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={onModalClose}
      className={changeClassName}
    >
      {imgUrl !== '' && (
        <div className="wishlist-measures-modal__know-size">
          <h2 className="wishlist-measures-modal__know-size__title">
            Descubra seu tamanho
          </h2>
          <Image
            alt="Descubra seu tamanho"
            baseUrl={`http://${account}.vtexassets.com/arquivos/${imgUrl}`}
            {...imgOptions}
          />
        </div>
      )}
      <span className="wishlist-measures-modal__divider" />
      <div className="wishlist-measures-modal__sizes">
        <h2 className="wishlist-measures-modal__sizes__title">
          {measureTitle}
        </h2>
        <div className="wishlist-measures-modal__radio-group">
          <RadioGroup
            name="radio-group"
            selectedValue={chosenOption}
            onChange={onValueChange}
          >
            {sortSkuVariations(productHasVariant)?.map(
              (variant: SkuFragmentFragment) => {
                const value = variant?.additionalProperty?.[0]?.value

                const skuAvailability =
                  variant.offers.offers.length > 0
                    ? variant?.offers?.offers[0]?.availability
                    : 'https://schema.org/InStock'

                const isNotAvailable =
                  skuAvailability !== 'https://schema.org/InStock'

                return (
                  <div key={value} className="wishlist-measures-modal__option">
                    <RadioOption
                      label={value}
                      value={value}
                      data-store-radio-disabled={isNotAvailable}
                      disabled={isNotAvailable}
                    >
                      <div className="wishlist-measures-modal__option-info">
                        <span data-store-radio-disabled={isNotAvailable}>
                          {value}
                        </span>
                      </div>
                    </RadioOption>
                  </div>
                )
              }
            )}
          </RadioGroup>
        </div>
      </div>
    </BaseModal>
  )
}

export default WishlistMeasuresModal
