import React, { useState } from 'react'
import IconButton from 'src/components/ui/VivaraIconButton'
import WishlistSizeSelector from 'src/components/ui/WishlistSizeSelector'
import { useProduct } from 'src/sdk/product/useProduct'
import ClockwiseIcon from 'src/images/svg/icon-clockwise'
import ShareIcon from 'src/images/svg/icon-share'
import TrashIcon from 'src/images/svg/icon-trash'
import type { OffersProps, WishlistProductType } from 'src/typings/wishlist'
import getNameVariantFromProduct from 'src/utils/wishlist/getNameVariantFromProduct'
import { Link } from 'gatsby'
import { OrderImagesByRegex } from 'src/utils/OrderImagesByRegex'

import WishlistBuyButton from './WishlistBuyButton'
import WishlistProductModals from './components/WishlistProductModals'
import './wishlist-product.scss'

interface IWishlistProduct {
  readonly product: WishlistProductType
  readonly productIndex: number
}

function WishlistProduct({ product, productIndex }: IWishlistProduct) {
  const baseClass = 'wishlistProduct'
  const [wasList, setWasList] = useState<string | undefined>()
  const [productData, setProductData] = useState<WishlistProductType>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [isChangeSizeModalOpen, setIsChangeSizeModalOpen] = useState(false)

  const [isCreateWishlistModalOpen, setIsCreateWishlistModalOpen] =
    useState(false)

  const [isAddProductToWishlistModalOpen, setIsAddProductToWishlistModalOpen] =
    useState(false)

  const { data } = useProduct(product?.productId)

  if (!data) {
    return null
  }

  const offers: OffersProps = data?.product?.offers?.offers
  const productInfo = data?.product
  const [offerArray] = offers?.map((e) => e)
  const offer = offerArray
  const productSize =
    data?.product?.additionalProperty?.find(
      (item) => item?.valueReference === 'SPECIFICATION'
    )?.value ?? product?.productSize

  const {
    name,
    image,
    breadcrumbList: { itemListElement },
  } = data?.product

  const productUrl = `${window?.location?.origin}${
    itemListElement[itemListElement?.length - 1]?.item
  }`

  const nameVariant = getNameVariantFromProduct(productInfo, productSize)
  const hasVariantValue =
    productInfo?.isVariantOf?.hasVariant?.length > 1 ||
    productInfo?.additionalProperty?.some(
      (property) => property.valueReference === 'SPECIFICATION'
    )

  return (
    <>
      <div className={`${baseClass}`}>
        <div className={`${baseClass}__info ${baseClass}__child`}>
          <img
            src={OrderImagesByRegex(image)?.[0]?.url}
            alt={name}
            className={`${baseClass}__info-image`}
          />
          <Link className={`${baseClass}__name-clamp`} to={productUrl}>
            {name}
          </Link>
        </div>
        <div className={`${baseClass}__actions ${baseClass}__child`}>
          {hasVariantValue && productSize && (
            <WishlistSizeSelector
              text={`${nameVariant} ${productSize}`}
              onClick={() => setIsChangeSizeModalOpen(true)}
            />
          )}
          <div className={`${baseClass}__icons`}>
            <IconButton
              icon={<ClockwiseIcon />}
              aria-label="Mover"
              onClick={() => setIsAddProductToWishlistModalOpen(true)}
            />
            <IconButton
              onClick={() => setIsShareModalOpen(true)}
              icon={<ShareIcon />}
              aria-label="Compartilhar"
            />
            <IconButton
              icon={<TrashIcon />}
              aria-label="Excluir"
              onClick={() => {
                setIsDeleteModalOpen(true)
                setProductData(product)
              }}
            />
          </div>
        </div>
        <WishlistBuyButton
          productHasVariant={productInfo}
          offer={offer}
          productInfo={productInfo}
          changeSize={productSize ?? ''}
        />
      </div>
      <WishlistProductModals
        product={productData}
        productInfo={productInfo}
        productUrl={productUrl}
        productSize={productSize}
        productIndex={productIndex}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isAddProductToWishlistModalOpen={isAddProductToWishlistModalOpen}
        setIsAddProductToWishlistModalOpen={setIsAddProductToWishlistModalOpen}
        isCreateWishlistModalOpen={isCreateWishlistModalOpen}
        setIsCreateWishlistModalOpen={setIsCreateWishlistModalOpen}
        wasList={wasList}
        setWasList={setWasList}
        isChangeSizeModalOpen={isChangeSizeModalOpen}
        setIsChangeSizeModalOpen={setIsChangeSizeModalOpen}
        isShareModalOpen={isShareModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
      />
    </>
  )
}

export default WishlistProduct
