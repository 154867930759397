import type { BrowserProductQueryQuery } from '@generated/graphql'
import React from 'react'
import BuyButton from 'src/components/ui/BuyButton'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'

type NewAdditionalPropertyType = Array<{
  propertyID: string
  name: string
  value: string
  valueReference: string
}>
type WishlistBuyButtonType = {
  productInfo: BrowserProductQueryQuery['product']
  offer:
    | {
        availability: string
        price: number
        listPrice: number
        seller: { identifier: string }
      }
    | undefined
  changeSize: string
  productHasVariant: BrowserProductQueryQuery['product']
}

function WishlistBuyButton({
  productInfo,
  offer,
  changeSize,
  productHasVariant,
}: WishlistBuyButtonType) {
  const {
    name,
    gtin,
    image: productImages,
    brand,
    isVariantOf,
    id,
    additionalProperty,
    sku,
  } = productInfo

  const { price, listPrice, seller } = offer ?? {
    price: productInfo.offers.offers?.[0]?.price,
    listPrice: productInfo.offers.offers?.[0]?.listPrice,
    seller: productInfo.offers.offers?.[0]?.seller,
  }

  const newSku = productHasVariant?.isVariantOf?.hasVariant.find(
    (variant: { additionalProperty: NewAdditionalPropertyType; sku: string }) =>
      variant.additionalProperty[0]?.value === changeSize
  )

  const newAdditionalProperty: NewAdditionalPropertyType =
    additionalProperty.map(
      (item: { propertyID: string; name: string; valueReference: string }) => {
        return {
          propertyID: item.propertyID,
          name: item.name,
          value: changeSize,
          valueReference: item.valueReference,
        }
      }
    )

  const buyProps = useBuyButton({
    id,
    price,
    listPrice,
    seller,
    quantity: 1,
    itemOffered: {
      sku: newSku?.sku ?? sku,
      name,
      gtin,
      image: productImages,
      brand,
      isVariantOf,
      additionalProperty: newAdditionalProperty,
    },
  })

  return <BuyButton {...buyProps}>ADICIONAR À SACOLA</BuyButton>
}

export default WishlistBuyButton
