import type { BrowserProductQueryQuery } from '@generated/graphql'

const getNameVariantFromProduct = (
  product: BrowserProductQueryQuery['product'],
  productSize?: string
) => {
  const nameVariant =
    product?.isVariantOf?.hasVariant?.find(
      (item) => item?.additionalProperty?.[0]?.value === productSize
    )?.additionalProperty?.[0]?.name ?? 'Tamanho'

  const name = nameVariant?.substring(0, 3)

  return nameVariant?.length > 3 ? `${name}.` : name
}

export default getNameVariantFromProduct
