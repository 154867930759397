import React from 'react'

interface Props {
  color?: string
  width?: string
  height?: string
}

const IconPlus = ({ color, width, height }: Props) => (
  <svg
    width={width ?? '25'}
    height={height ?? '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8755 6.20875C12.8755 5.82215 12.5621 5.50875 12.1755 5.50875C11.7889 5.50875 11.4755 5.82215 11.4755 6.20875V11.6339H6.05055C5.66395 11.6339 5.35055 11.9473 5.35055 12.3339C5.35055 12.7205 5.66395 13.0339 6.05055 13.0339H11.4755V18.4588C11.4755 18.8454 11.7889 19.1588 12.1755 19.1588C12.5621 19.1588 12.8755 18.8454 12.8755 18.4588V13.0339H18.3006C18.6872 13.0339 19.0006 12.7205 19.0006 12.3339C19.0006 11.9473 18.6872 11.6339 18.3006 11.6339H12.8755V6.20875Z"
      fill={color ?? '#F08769'}
    />
  </svg>
)

export default IconPlus
