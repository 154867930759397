import type { SkuFragmentFragment } from '@generated/graphql'

const getSkuFromValueProperty = (
  variants: SkuFragmentFragment[],
  option: string
) =>
  variants?.find(
    (variant) => variant?.additionalProperty?.[0]?.value === option
  )?.sku

export default getSkuFromValueProperty
