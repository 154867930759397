import React from 'react'
import ConfirmationModal from 'src/components/modal/ConfirmationModal'
import AddToWishlistModal from 'src/components/modal/AddToWishlistModal'
import SingleInputModal from 'src/components/modal/SingleInputModal'
import WishlistMeasuresModal from 'src/components/modal/WishlistMeasuresModal'
import ShareProductModal from 'src/components/modal/ShareProductModal'
import type { WishlistProductType } from 'src/typings/wishlist'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'

import WishlistNotification from '../../../WishlistNotification'

interface IWishlistProductModals {
  product: WishlistProductType | undefined
  productInfo: BrowserProductQueryQuery['product']
  productIndex: number
  productUrl: string
  productSize: string
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isAddProductToWishlistModalOpen: boolean
  setIsAddProductToWishlistModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
  isCreateWishlistModalOpen: boolean
  setIsCreateWishlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  wasList: string | undefined
  setWasList: React.Dispatch<React.SetStateAction<string | undefined>>
  isChangeSizeModalOpen: boolean
  setIsChangeSizeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isShareModalOpen: boolean
  setIsShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function WishlistProductModals({
  product,
  productInfo,
  productUrl,
  productIndex,
  productSize,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isAddProductToWishlistModalOpen,
  setIsAddProductToWishlistModalOpen,
  isCreateWishlistModalOpen,
  setIsCreateWishlistModalOpen,
  wasList,
  setWasList,
  isChangeSizeModalOpen,
  setIsChangeSizeModalOpen,
  isShareModalOpen,
  setIsShareModalOpen,
}: IWishlistProductModals) {
  const {
    breadcrumbList: { itemListElement },
  } = productInfo

  const { deleteProduct, createWishlist } = useWishlistContext()

  const onDeleteModalClose = async () => {
    if (product?.productId && (await deleteProduct(product?.productId))) {
      setWasList('deleteProductWishlist')
    }
  }

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationModal
          headerText="Deseja excluir este produto?"
          bodyText="Ao excluir este produto ele deixará de fazer parte da sua lista de favoritos. Caso queira adicioná-lo novamente é só favoritar, você já sabe!"
          denyText="Cancelar"
          confirmText="Excluir produto"
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onConfirmAction={() => onDeleteModalClose()}
        />
      )}
      {isAddProductToWishlistModalOpen && (
        <AddToWishlistModal
          productIndex={productIndex}
          isOpen={isAddProductToWishlistModalOpen}
          setIsOpen={setIsAddProductToWishlistModalOpen}
          openWishlistModal={() => setIsCreateWishlistModalOpen(true)}
          finalAction={() => setWasList('changeProductWishlist')}
          typeAction="changeProductWishlist"
          hideCurrent
        />
      )}
      {isCreateWishlistModalOpen && (
        <SingleInputModal
          title="Dê um nome para sua lista"
          btnText="Salvar"
          isOpen={isCreateWishlistModalOpen}
          setIsOpen={setIsCreateWishlistModalOpen}
          modalAction={createWishlist}
          onClose={() => setIsAddProductToWishlistModalOpen(true)}
        />
      )}
      {isChangeSizeModalOpen && (
        <WishlistMeasuresModal
          isOpen={isChangeSizeModalOpen}
          setIsOpen={setIsChangeSizeModalOpen}
          currentSize={productSize ?? '0'}
          productIndex={productIndex}
          productHasVariant={productInfo?.isVariantOf?.hasVariant}
          categoryTree={
            itemListElement || [
              {
                item: '',
                name: '',
                position: 0,
              },
            ]
          }
        />
      )}
      {wasList && (
        <WishlistNotification
          typeNotification={wasList}
          setWasList={setWasList}
          pageWishlist
        />
      )}
      {isShareModalOpen && (
        <ShareProductModal
          setIsShareModalOpen={setIsShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          productUrl={productUrl ?? ''}
        />
      )}
    </>
  )
}

export default WishlistProductModals
