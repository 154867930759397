import React from 'react'

import ConfirmationModal from '../../../../modal/ConfirmationModal'
import SingleInputModal from '../../../../modal/SingleInputModal'
import WishlistNotification from '../../../WishlistNotification'

interface IWishlistModals {
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onDeleteClick: () => void
  isEditWishlistModalOpen: boolean
  setIsEditWishlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  listName: string
  setListName: React.Dispatch<React.SetStateAction<string>>
  changeNameWishlist: (newName: string) => Promise<void>
  wasList: string | undefined
  setWasList: React.Dispatch<React.SetStateAction<string | undefined>>
}

function WishlistModals({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  onDeleteClick,
  isEditWishlistModalOpen,
  listName,
  setListName,
  setIsEditWishlistModalOpen,
  changeNameWishlist,
  wasList,
  setWasList,
}: IWishlistModals) {
  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationModal
          headerText="Tem certeza que deseja excluir?"
          bodyText="Ao excluir sua lista você exclui também os produtos que favoritou. Essa ação não poderá ser desfeita."
          denyText="Cancelar"
          confirmText="Sim, excluir"
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onConfirmAction={onDeleteClick}
        />
      )}
      {isEditWishlistModalOpen && (
        <SingleInputModal
          title="Edite o nome da sua lista"
          btnText="Salvar"
          isNewList={false}
          inputText={listName}
          setInputText={setListName}
          isOpen={isEditWishlistModalOpen}
          setIsOpen={setIsEditWishlistModalOpen}
          modalAction={changeNameWishlist}
          finalAction={() => setWasList('changeWishlist')}
        />
      )}
      {wasList && (
        <WishlistNotification
          typeNotification={wasList}
          setWasList={setWasList}
          pageWishlist
        />
      )}
    </>
  )
}

export default WishlistModals
