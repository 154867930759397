import React, { useEffect, useState } from 'react'
import MyAccountBody from 'src/components/common/MyAccountBody'
import { navigate } from 'gatsby'
import type { WishlistProductType } from 'src/typings/wishlist'
import getWishlistFromId from 'src/utils/wishlist/getWishlistFromId'
import findMissingProductIdsOnInsiderWishlistObject from 'src/contexts/WishlistContext/utils/findMissingProductIdsOnInsiderWishlistObject'

import WishlistProduct from '../WishlistProduct'
import { useWishlistContext } from '../../../contexts/WishlistContext/wishlist-context'
import WishlistActions from './components/WishlistActions'
import WishlistModals from './components/WishlistModals'
import WishListInsiderUpdater from '../WishListInsiderUpdater'
import './wishlist-details.scss'

interface IWishlistDetails {
  wishlistId: string | undefined
}

function WishlistDetails({ wishlistId }: IWishlistDetails) {
  const {
    lists,
    changeNameWishlist,
    deleteWishlist,
    updateCurrentWishlist,
    currentWishlist,
  } = useWishlistContext()

  const list = getWishlistFromId(lists, wishlistId) ?? null
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditWishlistModalOpen, setIsEditWishlistModalOpen] = useState(false)
  const [listName, setListName] = useState(list?.name ?? '')
  const [wasList, setWasList] = useState<string | undefined>()
  const [productIdsForInsiderUpdate, setProductIdsForInsiderUpdate] = useState<
    string[]
  >([])

  useEffect(() => {
    if (list !== null) {
      updateCurrentWishlist(list)
    }

    return () => {
      updateCurrentWishlist()
    }
  }, [list])

  const onDeleteClick = async () => {
    navigate('/wishlist')

    if (await deleteWishlist()) {
      setWasList('deleteWishlist')
    }
  }

  useEffect(() => {
    const missingProductIds =
      findMissingProductIdsOnInsiderWishlistObject(lists)

    if (missingProductIds.length === 0) {
      return
    }

    setProductIdsForInsiderUpdate(missingProductIds)
  }, [lists])

  useEffect(() => {
    window.insider_object = window.insider_object || {}

    window.insider_object.page = {
      type: 'Wishlist',
      originalType: 'Wishlist',
    }

    if (
      currentWishlist.id &&
      currentWishlist.id === window.insider_object?.wishlist?.id
    ) {
      return
    }

    window.insider_object.wishlist = {
      currency: 'BRL',
      total: window.insider_object?.wishlist?.total ?? 0,
      line_items:
        window.insider_object?.wishlist?.id === undefined
          ? window.insider_object?.wishlist?.line_items ?? []
          : [],
      id: currentWishlist.id,
    }
  }, [currentWishlist])

  return (
    <MyAccountBody
      title={listName}
      iconType="return"
      onIconClickAnchor="/wishlist"
      moreActions={
        <WishlistActions
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setIsEditWishlistModalOpen={setIsEditWishlistModalOpen}
          isDefaultWishlist={list?.isDefault ?? false}
        />
      }
    >
      {productIdsForInsiderUpdate.map((productId) => (
        <WishListInsiderUpdater productId={productId} key={productId} />
      ))}
      <div className="wishlist-details">
        {currentWishlist?.productList?.length
          ? currentWishlist.productList?.map(
              (product: WishlistProductType, index: number) => (
                <WishlistProduct
                  key={index}
                  product={product}
                  productIndex={index}
                />
              )
            )
          : list !== null && (
              <p className="wishlist-details__no-products">
                Você ainda não adicionou nenhum produto
              </p>
            )}
      </div>
      <WishlistModals
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        onDeleteClick={onDeleteClick}
        isEditWishlistModalOpen={isEditWishlistModalOpen}
        listName={listName}
        setListName={setListName}
        setIsEditWishlistModalOpen={setIsEditWishlistModalOpen}
        changeNameWishlist={changeNameWishlist}
        wasList={wasList}
        setWasList={setWasList}
      />
    </MyAccountBody>
  )
}

export default WishlistDetails
